import React from "react";
import { useEffect, useState } from 'react';
import Alerts from '../helpers/Alerts';
import Loading from '../helpers/Loader';
import clientInformation from '../login/ClientInfo';
import { useNavigate } from "react-router-dom";
import { GenerateToken, GetCustomerInfo, GetBalance, IsValidToken, GetIPAddress } from "../../services/AuthenticationService"
import { useLocation } from 'react-router-dom'
import { GetPreferredLimits } from "../../services/DepositService"

export const LoginComponent = () => {
    const clientInfo = clientInformation();
    const navigate = useNavigate();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const [isProcessing, setIsProcessing] = useState(false);
    let baseURL = process.env.REACT_APP_BASEURL;

    useEffect(() => {
        if ((searchParams.get('Token') !== null) || (searchParams.get('CustomerPIN') !== null)) {
            setIsProcessing(true);
            autoLogin(searchParams.get('CustomerPIN'), searchParams.get('Password'), searchParams.get('InstanceID'), searchParams.get('SkinID'), searchParams.get('BookID'),
                searchParams.get('BonusCode'), searchParams.get('Language'), searchParams.get('Token'), searchParams.get('Udf1'), searchParams.get('FixedAmount'));
        }
    }, []);

    async function autoLogin(user, password, instanceID, SkinID, BookID, BonusCode, Language, Token, Udf1, FixedAmount) {
        localStorage.setItem('User', user);

        if (instanceID === null) {
            instanceID = "1";
        }

        if (SkinID === null && instanceID === 1) {
            SkinID = "1";
        }

        if (BookID === null) {
            BookID = "1";
        }

        if (baseURL.includes('cashier123')) {
            if (instanceID === '1') {
                import("../../App-Wintoro.css");
            } else {
                import("../../App-BetAdrian.css");
            }
        }

        if (Token !== null) {
            
            await IsValidToken(Token).then(async function (response) {
                setIsProcessing(false);
                if ((response.Authentication.Token !== null && response.Authentication.Token !== "null")) {
                    localStorage.setItem('Udf1', Udf1);
                    localStorage.setItem('userLogin', user);
                    localStorage.setItem('FixedAmount', FixedAmount);
                    localStorage.setItem('Token', Token);
                }
                else {
                    clientInfo.Error = true;
                    Alerts.ShowAlert('Error', 'Error getting token ' + response.Authentication.errordescription, 'error');
                }
            }).catch(function (error) {
                    setIsProcessing(false);
                    clientInfo.Error = true;
                    Alerts.ShowAlert('Error', error.response.data.Authentication.errordescription, 'error');
                })
        }
        else {
           
            await GetToken(user, password, instanceID, SkinID, BookID);

            if (!clientInfo.Error) {
                localStorage.setItem('Udf1', Udf1);
                localStorage.setItem('userLogin', user);
                localStorage.setItem('FixedAmount', FixedAmount);
                localStorage.setItem('InstanceID', instanceID);
            }

            

        }
        setIsProcessing(false);
        if (!clientInfo.Error) {
            if (searchParams.get('Mode') != '' && searchParams.get('Mode') == 'Deposit') {
                navigate('/deposits/');
            }
            else if (searchParams.get('Mode') != '' && searchParams.get('Mode') == 'Withdrawal') {
                navigate('/payouts/');
            }
            else {
                navigate('/main/');
            }
        }
    }

    async function GetToken(user, pass, instanceId, SkinId, BookId) {
        await GenerateToken(user, pass, instanceId, SkinId, BookId).then(async function (response) {
            if (response.Authentication.Token !== null && response.Authentication.Token !== 'null') {
                localStorage.setItem('Token', response.Authentication.Token);
                if (response.Authentication.AllFieldsComplete) {
                    clientInfo.Error = false;
                    await CustomerInfo();
                    await Balance();
                    await PreferredLimits();
                    await GetClientIPAddress();

                }
                else {
                    await Balance();
                    await PreferredLimits();
                    await GetClientIPAddress();
                    navigate('/missing/');
                    clientInfo.Error = true;
                }
            }
            else {
                clientInfo.Error = true;
                Alerts.ShowAlert('Error', 'Error getting token: ' + response.Authentication.errordescription, 'error');
            }
        }).catch(function (error) {
                clientInfo.Error = true;
                Alerts.ShowAlert('Error', 'Error getting token: ' + error.response.data.Authentication.errordescription, 'error');
            })

    }

    async function GetClientIPAddress() {
        await GetIPAddress().then(function (response) {
            localStorage.setItem('IPAddress', response.ip);
        })

    }

    async function CustomerInfo() {
        await GetCustomerInfo(localStorage.getItem('Token')).then(function (response) {

            if (response.Authentication.ErrorDescription !== "Invalid input data") {
                clientInfo.funcMapCustomer(response);

            }
            else {
                clientInfo.Error = true;
                Alerts.ShowAlert('Error', 'Error getting customer info: ' + response.Authentication.ErrorDescription, 'error');

            }


        })
            .catch(function (error) {
                clientInfo.Error = true;
                Alerts.ShowAlert('Error', 'Error getting customer info: ' + error.response.data.Authentication.ErrorDescription, 'error');
            })
    }

    async function Balance() {
        await GetBalance(localStorage.getItem('Token')).then(function (response) {

            if (response.Authentication.ErrorDescription === '') {
                clientInfo.funcMapBalance(response);

            }
            else {
                clientInfo.Error = true;
                Alerts.ShowAlert('Error', 'Error getting balance: ' + response.Authentication.ErrorDescription, 'error');

            }

        })
            .catch(function (error) {
                clientInfo.Error = true;
                Alerts.ShowAlert('Error', 'Error getting balance: ' + error.response.data.Authentication.ErrorDescription, 'error');
            })

    }

    async function PreferredLimits() {
        await GetPreferredLimits(localStorage.getItem('Token')).then(async function (response) {

            var i = 0;
            const filtered = response.Authentication.map(item => {

                const {
                    IconURL,
                    DepositName,
                    DepositID,
                    CreditCardTypeID,
                    PaymentType,
                    ImageName,
                    MinDeposit,
                    MaxDeposit,
                    ProcessorID,
                    RegCardID,
                    CardNumber,
                    ExpYear
                } = item;
                if ((item.PaymentType === "" ||
                    item.PaymentType === "Crypto" ||
                    item.PaymentType === "Western Union" ||
                    item.PaymentType === "Money Gram" ||
                    item.PaymentType === "generic")
                    ||
                    (item.PaymentType === "EWallet"
                        && (item.ImageName === "neteller" ||
                            item.ImageName.toLowerCase() === "paysafecard" ||
                            item.ImageName.toLowerCase() === "paypal" ||
                            item.ImageName.toLowerCase() === "braintree" ||
                            item.ImageName.toLowerCase() === "googlepay" ||
                            item.ImageName.toLowerCase() === "applepay" ||
                            item.ImageName.toLowerCase() === "premieret" ||
                            item.ImageName.toLowerCase() === "premierint" ||
                            item.ImageName.toLowerCase() === "premierplus" ||
                            item.ImageName.toLowerCase() === "pagomovil" ||
                            item.ImageName.toLowerCase() === "wipay" ||
                            item.ImageName.toLowerCase() === "quickervoucher" ||
                            item.ImageName.toLowerCase() === "quickerzelle" ||
                            item.ImageName.toLowerCase() === "apexglobal" ||
                            item.ImageName.toLowerCase() === "marketusa" ||
                            item.ImageName.toLowerCase() === "upay"
                        ))) {
                    return {
                        Key: i++,
                        IconURL,
                        DepositName,
                        DepositID,
                        CreditCardTypeID,
                        PaymentType,
                        ImageName,
                        MinDeposit,
                        MaxDeposit,
                        ProcessorID,
                        RegCardID,
                        CardNumber,
                        ExpYear
                    };
                }
            });
            localStorage.setItem('ArrayPriorityMethods', JSON.stringify(filtered.filter(function (element) { return element !== undefined; })));
        }).catch(function (error) {


        })
    }

    async function Login(user, pass) {
        if ((user !== '') || (pass !== '')) {
            setIsProcessing(true);
            localStorage.setItem('User', user);
            await GetToken(user, pass, "1", "1", "1");

            if (!clientInfo.Error) {
                navigate('/main/');
            }
        }
        else {
            Alerts.ShowAlert('Information', 'Username and password required', 'info');
        }

    }

    const handleSubmit = (event) => {
        event.preventDefault();
        
        var data = new FormData(event.target);
        let { username, password } = Object.fromEntries(data.entries());

        Login(username, password);
    }

    return (
        <div>
            <div className="limiter">
                <div className="container-login100 ">
                    <div className="form-group">
                        <form onSubmit={handleSubmit}>
                            <input className="input-field" type="text" placeholder="username" name="username" required />
                            <input className="input-field" type="password" placeholder="password" name="password" required />

                            <input type="submit" value="login" />
                        </form>
                    </div>
                </div>
            </div>
            {
                isProcessing && <Loading />
            }
        </div>
    )
}


